import { useAuth } from "contexts/auth/useAuth";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export function RequireAuth() {
  const location = useLocation();

  const { user } = useAuth();

  const storagedRedirectToEvent = sessionStorage.getItem(
    "@arbo:redirectToEvent"
  );

  if (user) {
    
    if (!user.confirmedAt) {
      return (
        <Navigate to="/confirmar-email" state={{ from: location }} replace />
      );
    }

    if (storagedRedirectToEvent) {
      sessionStorage.removeItem("@arbo:redirectToEvent");

      return (
        <Navigate
          to={storagedRedirectToEvent}
          state={{ from: location }}
          replace
        />
      );
    }

    return <Outlet />;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
}
