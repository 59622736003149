import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Image,
  Radio,
  RadioGroup,
  Spinner,
  Text,
  Textarea,
  useBreakpointValue,
  useDisclosure
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Header } from "components/Layout/Dashboard/Header";
import { Paper } from "components/Paper";
import { ExitQuiz } from "components/Quiz/ExitQuiz";
import { ReportModal } from "components/Quiz/ReportModal";
import { useAuth } from "contexts/auth/useAuth";
import { useToast } from "contexts/layout/toast/useToast";
import { endOfMinute, isPast } from "date-fns";
import {
  useCreateSubmissionAnswers,
  useEvent,
  useEventLookup,
  useEventQuestionsRestricted,
  useQuestionsAnswerEvent,
  useUpdateSubmissionAnswers
} from "hooks/event";
import {
  EventStatusLookupEnum,
  MultipleChoiceAnswer,
  MultipleChoiceQuestion,
  OpenAnswer,
  SubmitQuizData
} from "interfaces/event";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FiCheck, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { GoAlert } from "react-icons/go";
import { Link, useNavigate, useParams } from "react-router-dom";
import congratulation from "../../assets/congratulation.svg";

import { questionFormSchema } from "./schema";

export function Question() {

  const OPEN_ANSWER_MAX_LENGTH = 2500;
  const { id } = useParams();
  const navigate = useNavigate();
  const isDesktop = useBreakpointValue({ lg: "none" });
  const { toast } = useToast();
  const { user } = useAuth();

  const { data: event } = useEvent(id);
  const { data: questions, isLoading } = useEventQuestionsRestricted(id);
  const { data: questionsAnswers, isSuccess: isQuestionAnswersSuccess } =
    useQuestionsAnswerEvent(id);
  const { data: lookUp } = useEventLookup(id);
  const {
    mutate: createSubmissionAnswers,
    isSuccess: isCreateSubmissionAnswersSuccess,
    isLoading: isCreateLoading,
  } = useCreateSubmissionAnswers(id);
  const { data: questionsAnswerEvent } = useQuestionsAnswerEvent(id);
  const {
    mutate: updateSubmissionAnswers,
    isSuccess: isUpdateSubmissionAnswersSuccess,
    isLoading: isUpdateLoading,
  } = useUpdateSubmissionAnswers(questionsAnswerEvent?._id);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const defaultValues: SubmitQuizData = {
    answers: questionsAnswers?.answers ?? [],
    isDraft: questionsAnswers?.isDraft ?? false,
  };

  const { handleSubmit, watch, reset, control } = useForm<SubmitQuizData>({
    defaultValues,
    resolver: yupResolver(questionFormSchema),
  });

  const answers = watch("answers");

  const multipleChoiceAnswer = watch(
    `answers.${currentQuestion}.multipleChoiceAnswer`
  );
  const openAnswer = watch(`answers.${currentQuestion}.openAnswer`);

  const {
    isOpen: isOpenReport,
    onOpen: onOpenReport,
    onClose: onCloseReport,
  } = useDisclosure();

  const {
    isOpen: isExitQuiz,
    onOpen: onOpenExitQuiz,
    onClose: onCloseExitQuiz,
  } = useDisclosure();

  const blockUpdateSubmission =
    !!event?.responsePeriodEnd &&
    isPast(endOfMinute(new Date(event.responsePeriodEnd)));

  const toNextQuestion = () => {
    if (questions && currentQuestion < questions.length - 1) {
      setCurrentQuestion((currentQuestion) => currentQuestion + 1);
    }
  };

  const toPreviousQuestion = () => {
    if (questions && currentQuestion >= questions[0].order) {
      setCurrentQuestion((currentQuestion) => currentQuestion - 1);
    }
  };

  const handleSubmitAnswers = ({ answers, isDraft }: SubmitQuizData) => {
    if (questions) {
      const answerData = {
        isDraft,
        answers: answers.map((answer, index) => ({
          id: answer.id,
          question: questions[index].id,
          multipleChoiceAnswer:
            (answer as MultipleChoiceAnswer).multipleChoiceAnswer ?? null,
          openAnswer: (answer as OpenAnswer).openAnswer ?? null,
        })),
      };
      if (id && lookUp !== EventStatusLookupEnum.ANSWERED) {
        createSubmissionAnswers(answerData);
      } else {
        updateSubmissionAnswers(answerData);
      }
    }
  };

  function onSubmit(submitQuizData: SubmitQuizData) {
    if (questions && currentQuestion !== questions.length - 1) {
      toNextQuestion();
    } else if (event && isPast(endOfMinute(new Date(event.responsePeriodEnd)))) {
      navigate(`/meus-eventos/${id}`);
    } else {
      handleSubmitAnswers(submitQuizData);
    }
  }

  useEffect(() => {
    if (isQuestionAnswersSuccess) {
      reset(defaultValues);
    }
  }, [isQuestionAnswersSuccess]);

  useEffect(() => {
    if (!event?.participants.includes(user?.id as string)) {
      toast("Você não é um participante deste evento.", "warning");
      navigate(`/meus-eventos/${id}`);
    }

    if (event?.isFinished) {
      toast("Este evento já foi finalizado.", "warning");
      navigate("/meus-eventos");
    }
  }, [event, user]);

  return (
    <>
      <Header title={!isDesktop ? "Questionário" : event?.name} />

      <Box bg="primary.400" h="9px" w="100%" borderTopRadius="4px">
        <Box
          bg="primary.500"
          h="9px"
          w={`${questions &&
            questions[currentQuestion]?.order &&
            (questions[currentQuestion].order / questions.length) * 100
            }%`}
          borderTopRadius="4px"
        />
      </Box>

      {isLoading ? (
        <Flex justifyContent="center" flex={1} align="center">
          <Spinner />
        </Flex>
      ) : (
        questions && (
          <Paper
            px={[8, "2.938rem"]}
            py="2rem"
            borderTopRadius={0}
            overflow="auto"
          >
            {isCreateSubmissionAnswersSuccess ||
              isUpdateSubmissionAnswersSuccess ? (
              <Flex
                direction="column"
                justifyContent="space-around"
                alignItems="center"
                height="100%"
                gap={8}
              >
                <Flex direction="column" gap={4}>
                  <Image src={congratulation} />
                  <Box>
                    <Text
                      fontSize="1.125rem"
                      color="gray.300"
                      fontWeight="700"
                      width={300}
                      textAlign="center"
                    >
                      Parabéns! Você concluiu o questionário.
                    </Text>
                    <Text color="gray.300" textAlign="center">
                      Aguarde a correção iniciar.
                    </Text>
                  </Box>
                </Flex>
                <Text
                  justifyContent="flex-end"
                  color="primary.500"
                  fontWeight="700"
                  textAlign="center"
                >
                  <Link to="/">Retornar para o Mural</Link>
                </Text>
              </Flex>
            ) : (
              <Flex
                justifyContent="space-between"
                as="form"
                onSubmit={handleSubmit(onSubmit)}
                w="100%"
                h="100%"
                direction="column"
              >
                <Flex direction="column" gap={4}>
                  <Flex justifyContent="space-between">
                    <FormLabel fontSize="0.875rem" color="gray.300">
                      Questão {questions[currentQuestion].order}
                    </FormLabel>

                    {isDesktop ? (
                      null
                      // <Button
                      //   onClick={onOpenExitQuiz}
                      //   variant="link"
                      //   alignSelf="flex-start"
                      //   isDisabled={!!questionsAnswers}
                      // >
                      //   Salvar e Sair
                      // </Button>
                    ) : (
                      <IconButton
                        height="24px"
                        width="24px"
                        borderRadius={5}
                        minW={0}
                        colorScheme="secondary"
                        onClick={onOpenReport}
                        icon={<GoAlert size="12px" />}
                        aria-label="report"
                      />
                    )}
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: questions[currentQuestion].title,
                      }}
                      fontSize="1.125rem"
                      color="gray.400"
                    />
                    {isDesktop && (
                      <IconButton
                        height="44px"
                        width="44px"
                        minW={0}
                        colorScheme="secondary"
                        onClick={onOpenReport}
                        icon={<GoAlert />}
                        aria-label="report"
                        borderRadius={10}
                      />
                    )}
                  </Flex>
                  {questions[currentQuestion]?.type === "OPEN" && (
                    <FormControl>
                      <Controller
                        control={control}
                        name={`answers.${currentQuestion}.openAnswer`}
                        render={({ field: { ...props } }) => (
                          <>
                            <Textarea
                              {...props}
                              placeholder="Escreva aqui sua resposta"
                              value={openAnswer ? String(openAnswer) : ""}
                              readOnly={blockUpdateSubmission}
                              maxLength={OPEN_ANSWER_MAX_LENGTH}
                            />
                            <span>{`Caracteres: ${String(openAnswer || "").length}/${OPEN_ANSWER_MAX_LENGTH}`} </span>
                          </>
                        )}
                      />
                    </FormControl>
                  )}
                  {questions[currentQuestion]?.type === "MULTIPLE_CHOICE" && (
                    <FormControl>
                      <Controller
                        control={control}
                        name={`answers.${currentQuestion}.multipleChoiceAnswer`}
                        render={({ field: { ...props } }) => (
                          <RadioGroup
                            {...props}
                            value={String(multipleChoiceAnswer)}
                            display="flex"
                            flexDirection="column"
                            gap={4}
                            isDisabled={blockUpdateSubmission}
                          >
                            {(
                              questions[
                              currentQuestion
                              ] as MultipleChoiceQuestion
                            ).choices.map(({ id, text }) => (
                              <Radio key={id} value={id}>
                                {text}
                              </Radio>
                            ))}
                          </RadioGroup>
                        )}
                      />
                    </FormControl>
                  )}
                </Flex>

                <Flex
                  justifyContent="space-between"
                  px={4}
                  py={8}
                  alignItems="center"
                >
                  {isDesktop ? (
                    <>
                      <Button
                        variant="link"
                        disabled={questions[currentQuestion].order === 1}
                        onClick={toPreviousQuestion}
                      >
                        Voltar
                      </Button>

                      <Text>
                        {questions[currentQuestion].order}/{questions.length}
                      </Text>

                      <Button
                        isLoading={isCreateLoading || isUpdateLoading}
                        type="submit"
                        px="25px"
                      >
                        {currentQuestion === questions.length - 1
                          ? "Finalizar"
                          : "Prosseguir"}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="outline"
                        width="3.438rem"
                        height="3.5rem"
                        borderRadius="55px"
                        disabled={questions[currentQuestion].order === 1}
                        onClick={toPreviousQuestion}
                      >
                        <Icon
                          as={FiChevronLeft}
                          bgColor="secondary"
                          color="secondary"
                        />
                      </Button>
                      <Text>
                        {questions[currentQuestion].order}/{questions.length}
                      </Text>

                      <Button
                        isLoading={isCreateLoading || isUpdateLoading}
                        px="25px"
                        type="submit"
                        width="3.438rem"
                        height="3.5rem"
                        borderRadius="55px"
                      >
                        <Icon
                          as={
                            currentQuestion === questions.length - 1
                              ? FiCheck
                              : FiChevronRight
                          }
                          bgColor="secondary"
                          color="secondary"
                        />
                      </Button>
                    </>
                  )}
                </Flex>

                <ReportModal
                  numberQuestion={questions[currentQuestion]?.order}
                  questionId={questions[currentQuestion]?.id}
                  isOpen={isOpenReport}
                  onClose={onCloseReport}
                  title={questions[currentQuestion]?.title}
                  choices={
                    (questions[currentQuestion] as MultipleChoiceQuestion)
                      ?.choices
                  }
                />

                <ExitQuiz
                  saveAnswer={async () =>
                    handleSubmitAnswers({ answers, isDraft: true })
                  }
                  isOpen={isExitQuiz}
                  onClose={onCloseExitQuiz}
                />
              </Flex>
            )}
          </Paper>
        )
      )}
    </>
  );
}
